import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ProgressBar from '##/components/ProgressBar';
import clsx from 'clsx';

import { IMarket } from './interfaces/IMarket';
import { ILocation } from '##/interfaces/ILocation';
import { LAYOUT_CONTAINER, shouldStoreComponentInRedux } from './utils/capi';
import { isLoggedIn, setGigyaUIDinAdobeDataLayer } from './utils/auth';
import aamTracker from './utils/tracking/aam-tracker';
import { handleRouteChange, showSidebarOnRouteChange } from './utils/route';
import detectAdBlock from './utils/detectAdBlock';
import { trackUxScreenEnter } from './utils/tracking/uxEvents';
import trackNielsenV60 from '##/utils/tracking/nielsen_v60';
import renderSection from './utils/renderSection';
import { getInlineStylesBasedOnTheme } from './utils/customTheme';
import { SUCCESSFUL_REGISTRATION_MESSAGE } from './state/registration/types';
import { ErrorPage } from './components/ErrorPage';
import renderComponent from './utils/renderComponent';
import { DEFAULT_FETCH_TIMEOUT, DEFAULT_PAGE_TITLE } from './utils/constants';
import sectionStyles from '##/components/_Section.pcss';
import AccountManagementPageContainer from './components/AccountManagementPage/AccountManagementPageContainer';
import FallbackLayoutContainer from './components/FallbackLayout/FallbackLayoutContainer';
import Header from './components/Header';
import SwiperButtonSvgDef from './components/Swiper/SwiperButtonSvgDef';
import { RenderReactLazy } from './utils/reactLazy';
import { lazy } from 'react';
import { PATHS } from '##/utils/route';
import { parseQueryString, redirectWithoutQueryString } from './utils/queries';
import { isBroadcastPage } from './utils/page';
import { useHistory } from 'react-router';
import { useRef } from 'react';
import axios from 'axios';
import { AnyComponent } from '@swm/types-component-api';
import { IPageMetaData } from './interfaces/IPageMetadata';
import { manipulateCapiData } from './utils/page';
import { IAppProps, IResponse, IState } from './AppTypes';
import { getImageProxyUrl } from './utils/images';

import styles from './App.pcss';

const CookieNotice = lazy(
  () =>
    import(/* webpackChunkName: "CookieNotice"*/ '##/components/CookieNotice'),
);
const UserFormOverlay = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "UserFormOverLay"*/ '##/components/UserFormOverlay'
    ),
);
const ThirdPartyCookiesVerifier = lazy(
  () =>
    import(
      /* webpackChunkName: "ThirdPartyCookiesVerifier"*/ '##/components/ThirdPartyCookiesVerifier'
    ),
);
const NotificationPrompt = lazy(
  () =>
    import(
      /* webpackChunkName: "NotificationPrompt"*/ '##/components/NotificationPrompt'
    ),
);

const { config } = window.swm;

export const App: React.FC<IAppProps> = ({
  checkIfPasswordRequired,
  clearComponents,
  displayCookieNotice,
  getToken,
  isAdBlockerActive = null,
  location,
  history,
  marketData,
  pageType,
  preventCapiReload,
  setAdBlockerStatus,
  setIsGDPRCountry,
  setIsLoggedIn,
  setMarketData,
  setPageInfo,
  setPreventCapiReload,
  setLiveAutoplay,
  sidebarMenuVisible,
  storeComponentById,
  storeComponentIdByType,
  showSidebarMenu,
  updateStoreFromLocalstorage,
  determineIfHeaderIsFloating,
  isFloatingHeader,
  statusCode,
  deviceRegistrationMessage,
  isError,
  resetPassword,
  signInSuccess,
  signOutSuccess,
  editProfile,
  changePassword,
  checkYourEmail,
  verificationRedirectURL,
  closeAccount,
  logout,
}) => {
  const isSuccessfullyConnected =
    deviceRegistrationMessage === SUCCESSFUL_REGISTRATION_MESSAGE;

  const configEnabledCookie = config.cookieNotice?.enabled;

  const configEnabledCookieVerifier = config.thirdPartyCookiesVerifier?.enabled;

  const currentHistory = useHistory();

  /*Through the second task (PR pullrequest/16436),
  I've adjusted the approach regarding states. Instead of using nested states, I've opted for separate
  states for each part of the application. I haven't made changes here because I had already implemented
  those adjustments separately in another task.*/
  const [state, setState] = useState<IState>({
    app: {
      market: {},
      pageTitle: '',
      pageEventName: '',
    },
    page: {
      components: [{ component: { componentData: { componentName: '' } } }],
      items: {},
      listingNavigation: false,
      type: '',
    },
    error: null,
  });

  const [screenSize, setScreenSize] = useState<number>(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [prevLocation, setPrevLocation] = useState(null);
  const [progressTimer, setProgressTimer] = useState(null);

  const prevProps = useRef({ location, pageType });

  const localStorageUpdated = () => {
    // NOTE: this 'localStorageUpdated' needs to be reviewed
    //       we should only call those two functions here when
    //       there was something changing from previous value
    //       that will improve performance (less js running)
    setIsLoggedIn(isLoggedIn());
    updateStoreFromLocalstorage();
  };

  const getJwtFromAuthManager = async () => {
    try {
      const data = await getToken(logout);
      return data?.token || null;
    } catch (error) {
      return null;
    }
  };

  const onRouteChanged = async (location: ILocation) => {
    const jwt: string | null = await getJwtFromAuthManager();

    // Check the url if sidebar should be displayed.
    showSidebarOnRouteChange({ sidebarMenuVisible, showSidebarMenu });

    await checkForAdBlocker();

    const { error, capiDataPromise, marketData } = (await handleRouteChange(
      location,
      jwt,
    )) as IResponse;

    aamTracker.routeChange();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        error,
      }));
    } else {
      const capiData = await capiDataPromise;
      const updates = await handleCapiData(marketData, capiData);

      // If loading a new list of components from CAPI clear the old list
      clearComponents();

      setState({
        ...updates,
      });

      if (typeof ProgressBar !== 'undefined' && ProgressBar !== null) {
        setShowProgressBar(false);
      }

      updateMarketData(updates);
    }
  };

  const updateMarketData = ({ app: { market } }) => {
    const doesUpdatesHaveNewData =
      marketData.market_id !== market.market_id ||
      marketData.postcode !== market.postcode;

    if (doesUpdatesHaveNewData) {
      setMarketData(market);
    }
  };

  const startCountryDetection = async () => {
    const response = await axios('/user/country', {
      method: 'get',
      timeout: DEFAULT_FETCH_TIMEOUT,
    });
    const isGDPRCountry = response.data;
    setIsGDPRCountry(isGDPRCountry);
  };

  const checkForAdBlocker = async () => {
    if (isAdBlockerActive === null) {
      const adBlockDetected = await detectAdBlock();
      setAdBlockerStatus(adBlockDetected);
    }

    return null;
  };

  const handleResize = () => {
    const newScreenSize = window.innerWidth;
    setScreenSize(newScreenSize);
  };

  const handleCapiData = async (
    marketData: IMarket,
    capiData: {
      components: [
        {
          component: {
            componentData: {
              componentName: string;
            };
          };
        },
      ];
      items: {
        [id: string]: AnyComponent;
      };
      listingNavigation?: boolean;
      metaData: IPageMetaData;
      title: string;
      shortPageTitle: string;
      type: string;
      validFor?: number; // NOTE: capi returns it as `undefined` when there isnt a validFor, so it's optional
    },
  ) => {
    const {
      type,
      title,
      shortPageTitle,
      metaData,
      listingNavigation,
      components,
    } = capiData;

    setPageInfo({
      canonicalUrl: metaData ? metaData.canonicalUrl : '',
      hasListingNavigation: !!listingNavigation,
      title,
      type,
      shortPageTitle,
    });

    const { pageEventName } = metaData;

    const appData = {
      market: marketData,
      pageEventName,
      pageTitle: title,
    };

    // Because the components are updated and not the page,
    // when switching between pages window.scrollTo(0, 0) needs to
    // be added to send the user to the top of the page.
    window.scrollTo(0, 0);

    trackingLogic(pageEventName, components, title);

    return {
      page: {
        ...manipulateCapiData(capiData),
        listingNavigation: !!listingNavigation,
      },
      error: null,
      app: appData,
    };
  };

  const trackingLogic = (
    pageEventName: string,
    components: [
      {
        component: {
          componentData: {
            componentName: string;
          };
        };
      },
    ],
    title: string,
  ) => {
    trackUxScreenEnter(pageEventName, components, !!isAdBlockerActive, title);
    const { nol_t: sdkInit } = window;
    if (sdkInit) {
      trackNielsenV60(sdkInit);
    }
  };

  const shouldIgnorePageComponent = ({ component }) => {
    const {
      page: { listingNavigation },
    } = state;
    // it has to check if listingNavigation is equals to false since some pages doesn't have this
    // property like home page, therefore, it is 'undefined' in home page.
    const shouldIgnoreNavigationComponent = listingNavigation === false;

    return shouldIgnoreNavigationComponent && component === 'navigation';
  };

  const renderPageComponentSection = (pageComponentSection, allItems) => {
    return renderSection(
      pageComponentSection.id,
      pageComponentSection.name,
      state,
      location,
      pageComponentSection.items,
      allItems,
    );
  };

  const renderPageComponent = ({ id, type }, index, allItems) => {
    const props = {
      ...state.page.items[id],
      // creating 'customProps' for any prop that doesn't come from capi and it's purely frontend/custom
      // just so we can group them and distinguish them moving forward
      customProps: { componentIndexInPage: index },
    };

    const capitalizedComponentType =
      type.charAt(0).toUpperCase() + type.slice(1);
    const styleName = `section${capitalizedComponentType}`;

    return (
      <section
        className={clsx(sectionStyles.sectionPage, sectionStyles[styleName])}
        style={getInlineStylesBasedOnTheme(type, props.theme, config)}
        key={id || index}
      >
        {renderComponent(id, type, props, allItems)}
      </section>
    );
  };

  const renderPageComponentItem = (item, index) => {
    const {
      page: { items },
    } = state;

    const { component, type } = item;

    if (type === 'section') {
      // Render a multiple grouped components
      return renderPageComponentSection(component, state.page.components);
    }

    if (items[component.id] && !shouldIgnorePageComponent(component)) {
      // Render a component
      return renderPageComponent(component, index, state.page.components);
    }

    return null;
  };

  const storeComponentsInRedux = (item) => {
    if (shouldStoreComponentInRedux(item.type)) {
      // Adding the relevent components to redux on page load
      storeComponentById(item);
      storeComponentIdByType({
        id: item.id,
        type: item.type,
      });
    } else if (item.type === LAYOUT_CONTAINER) {
      // Recursively store nested items
      item.items.forEach(storeComponentsInRedux);
    }
  };

  //store page component in Redux, without rendering it
  const storePageComponentItemInRedux = (item) => {
    const { items } = state.page;

    const { component, type } = item;

    if (type === 'section') {
      component.items.forEach(storeComponentsInRedux);
    }

    if (items[component.id] && !shouldIgnorePageComponent(component)) {
      storeComponentsInRedux(component);
    }

    return null;
  };

  useEffect(() => {
    const initializePage = async () => {
      if (!window.aamDataLayer) {
        aamTracker.init();
      }

      window.addEventListener('storage', localStorageUpdated);

      // checkIfPasswordRequired();

      if (
        pageType === 'broadcastPage' ||
        (pageType === 'listingPage' &&
          window.location.href.indexOf('autoplay=true') !== -1)
      ) {
        determineIfHeaderIsFloating(true);
      } else {
        determineIfHeaderIsFloating(false);
      }

      startCountryDetection();
      if (location.pathname === PATHS.NEW_PASSWORD_LEGACY) {
        const query = location.search.slice(1);
        window.location.replace(`/?overlay=${PATHS.NEW_PASSWORD}&${query}`);
      } else {
        await onRouteChanged(location);
      }
    };

    initializePage();
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('storage', localStorageUpdated);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // here we call a function that will store the components in redux,
    // so that we don't call it from render which causes the error to appear
    if (state.page.components.length > 1) {
      state.page.components.map(storePageComponentItemInRedux);
    }
  }, [state.page]);

  useEffect(() => {
    const fetchData = async () => {
      const queryString = parseQueryString(location.search);

      if (
        window.swm.config.player.autoplayWorkaround &&
        isBroadcastPage(pageType) &&
        queryString.autoplay
      ) {
        setLiveAutoplay(true);
        redirectWithoutQueryString('autoplay', currentHistory);
        return;
      }

      if (pageType !== prevProps.current.pageType) {
        if (
          pageType === 'broadcastPage' ||
          (pageType === 'listingPage' &&
            window.location.href.indexOf('autoplay=true') !== -1 &&
            isLoggedIn())
        ) {
          determineIfHeaderIsFloating(true);
        } else {
          determineIfHeaderIsFloating(false);
        }
      }

      if (location.pathname === PATHS.NEW_PASSWORD_LEGACY) {
        const query = location.search.slice(1);
        window.swm.error = null;
        window.location.replace(`/?overlay=${PATHS.NEW_PASSWORD}&${query}`);
      } else {
        if (!preventCapiReload) {
          if (
            location.search !== prevProps.current.location.search ||
            location.pathname !== prevProps.current.location.pathname
          ) {
            await onRouteChanged(location);
          }
        } else {
          setPreventCapiReload(false);
        }

        setGigyaUIDinAdobeDataLayer();
      }

      prevProps.current = { location, pageType };

      // TODO: Investigate removing prevLocation and progressTimer from state,
      // because they have no relationship to application state. They are only
      // used as references to check if a previous instance of themselves exists.
      // Therefore, we don't need getDerivedStateFromProps as well. All logic can
      // be moved to componentDidMount and refer to class properties
      // Only update on the path not the full query string
      if (location.pathname !== (prevLocation && prevLocation.pathname)) {
        let updates: object | any = {};
        // Only call show if there is an existing progress instance on the page
        if (typeof ProgressBar !== 'undefined' && ProgressBar !== null) {
          setShowProgressBar(true);
        }

        setPrevLocation(location);
        setProgressTimer(updates);
      }
    };

    fetchData();
  }, [
    location,
    pageType,
    preventCapiReload,
    setPreventCapiReload,
    setLiveAutoplay,
    prevLocation,
    progressTimer,
    setPrevLocation,
    setProgressTimer,
    determineIfHeaderIsFloating,
    history,
  ]);

  const isConnectPage = window.location.pathname === '/connect';

  const getImageForScreenSize = (
    mobileImage: string,
    tabletImage: string,
    desktopImage: string,
  ) => {
    if (screenSize < 568 && screenSize > 0) {
      return getImageProxyUrl({ url: mobileImage });
    } else if (screenSize < 1025 && screenSize > 0) {
      return getImageProxyUrl({ url: tabletImage });
    }
    return getImageProxyUrl({ url: desktopImage });
  };

  const backgroundImage = window.swm.config.backgroundImages
    ? isConnectPage
      ? getImageForScreenSize(
          window.swm.config.backgroundImages.connectPage.mobile,
          window.swm.config.backgroundImages.connectPage.tablet,
          window.swm.config.backgroundImages.connectPage.desktop,
        )
      : getImageForScreenSize(
          window.swm.config.backgroundImages.watchlistPage.mobile,
          window.swm.config.backgroundImages.watchlistPage.tablet,
          window.swm.config.backgroundImages.watchlistPage.desktop,
        )
    : undefined;

  if (state.error) {
    return (
      <ErrorPage
        errorTitle={state.error.title}
        errorMessage={state.error.message}
        redirect={state.error.redirect}
        linkUrl={state.error.linkUrl}
        linkMessage={state.error.linkMessage}
        errorStatus={state.error.status}
      />
    );
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{state.app.pageTitle || DEFAULT_PAGE_TITLE}</title>
        </Helmet>
        <div
          style={{
            ...(window.location.pathname === '/connect' ||
            (state.page.components.length <= 1 &&
              !state.page.components[0]?.component?.componentData &&
              state.page.components[0] &&
              window.location.pathname === '/watchlist')
              ? { backgroundImage: `url(${backgroundImage})` }
              : {}),
          }}
          className={clsx(styles.appSectionContainer, {
            [styles.blackBackgroundSection]:
              state.page.components[0].component.name === 'VodSection',
            [styles.fallbackLayoutBackgroundImage]:
              window.location.pathname === '/connect' ||
              (state.page.components.length <= 1 &&
                !state.page.components[0].component.componentData &&
                state.page.components[0] &&
                window.location.pathname === '/watchlist'),
          })}
        >
          <section
            className={clsx(
              sectionStyles.sectionPage,
              isFloatingHeader
                ? sectionStyles.sectionFloatingNavigation
                : sectionStyles.sectionNavigation,
            )}
          >
            <Header
              topOverlap={state.page.components[0].component?.topOverlap}
              isFloating={isFloatingHeader}
              showProgressBar={showProgressBar}
            />
          </section>
          {location.pathname === '/manage-account' && (
            <AccountManagementPageContainer />
          )}
          {state.page.components.length <= 1 &&
            !state.page.components[0].component.componentData && (
              <FallbackLayoutContainer />
            )}
          {state.page.components.map(renderPageComponentItem)}
        </div>
      </HelmetProvider>
      <SwiperButtonSvgDef />
      {sidebarMenuVisible &&
        RenderReactLazy(UserFormOverlay, null, 'userFormOverlay')}
      {configEnabledCookie &&
        displayCookieNotice &&
        RenderReactLazy(CookieNotice, null, 'cookieNotice')}
      {!editProfile &&
        !changePassword &&
        RenderReactLazy(NotificationPrompt, null, 'notificationPrompt', {
          statusCode: statusCode || history?.state?.statusCode,
          showConnectYourTvPrompt: isSuccessfullyConnected,
          isError,
          resetPasswordSuccess: resetPassword,
          signInSuccess,
          registerSuccess: isLoggedIn() && !!history?.state?.registerSuccess,
          signOutSuccess: signOutSuccess && !checkYourEmail && !closeAccount,
          showCheckYourEmailPrompt: checkYourEmail,
          verificationRedirectURL,
          closeAccount,
        })}
      {configEnabledCookieVerifier &&
        RenderReactLazy(
          ThirdPartyCookiesVerifier,
          null,
          'ThirdPartyCookiesVerifier',
        )}
    </>
  );
};
