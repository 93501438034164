import { getCapiParameters } from '##/utils/capi';
import { addQueryParamsToUrl } from '##/utils/queries';
import { logError } from '##/utils/trackJs';
import { INavItem } from '##/interfaces/INavigation';
import { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { marketName } from '##/state/market';
/**
 * Fallback navigation items in case of an error in fetch
 */
export const NAV_ITEMS_FALLBACK: INavItem[] = [
  {
    title: 'Home',
    contentLink: '/',
    alternativeUrl: '',
    tintable: false,
    type: 'navItem',
  },
  {
    title: 'Live TV',
    contentLink: '/live-tv',
    alternativeUrl: '',
    tintable: false,
    type: 'navItem',
  },
  {
    title: 'Shows',
    contentLink: '/shows-a-z',
    alternativeUrl: '',
    tintable: false,
    type: 'navItem',
  },
  {
    title: 'Sport',
    contentLink: '/sport',
    alternativeUrl: '',
    tintable: false,
    type: 'navItem',
  },
];

/**
 * Fetches navWebData items otherwise returns a default nav array
 *
 * @param marketId
 */
export const getNavItemData = async (marketId: number): Promise<INavItem[]> => {
  const {
    config,
    config: {
      services: {
        endpoints: { navigation },
      },
    },
  } = window.swm;

  // base Url for new nav-web endpoint
  const baseUrl = navigation;
  if (!baseUrl) {
    return NAV_ITEMS_FALLBACK;
  }

  try {
    const urlWithParams: string = addQueryParamsToUrl(
      baseUrl,
      getCapiParameters(config, marketId, false),
    );
    const navWebData = await fetch(urlWithParams);
    if (navWebData.ok) {
      const data = await navWebData.json();
      if (data.items) {
        return data.items;
      }
      throw new Error('navWebData.items does not exist');
    }
    throw new Error('navWebData status is not ok');
  } catch (error) {
    // track the error with trackjs and return the default nav items
    logError("Couldn't fetch navigation data", error);
    return NAV_ITEMS_FALLBACK;
  }
};

export const useGetNavItemData = (): INavItem[] => {
  const [navItems, setNavItems] = useState<INavItem[]>([]);

  const { market_id: marketId } = useSelector(
    (state) => state[marketName].data,
    shallowEqual,
  );

  useEffect(() => {
    const fetchData = async () => {
      const items = await getNavItemData(marketId);
      setNavItems(items);
    };
    fetchData();
  }, []);

  return navItems;
};
