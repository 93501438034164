import React, { FC } from 'react';
import clsx from 'clsx';

import { Button } from '##/components/Button';
import { Checkbox } from './Checkbox';
import { Spinner } from '##/components/Spinner';
import { TextElement } from '##/components/TextElement';

import styles from './CloseYourAccount.pcss';
import { trimValue } from '##/utils/formValidation';

export const CloseYourAccount: FC<any> = ({
  handleSubmit,
  handleChange,
  values,
  isSubmitting,
}) => {
  const { closeAccount } = window.swm.config.auth;

  return (
    <div className={styles.closeYourAccountContainer}>
      <TextElement textType="title" cssClasses={['body1', 'white', 'fw600']}>
        Close your account
      </TextElement>
      <TextElement
        className={styles.text}
        cssClasses={['fw300', 'block', 'white']}
      >
        {closeAccount.descriptionText}
      </TextElement>
      <form onSubmit={handleSubmit}>
        <Checkbox
          name="disableAccount"
          value={values.disableAccount}
          label={closeAccount.checkboxText}
          handleChange={trimValue(handleChange)}
        />
        <div
          className={clsx(styles.submitWrapper, {
            [styles.submitingSubmitWrapper]: isSubmitting,
          })}
        >
          {isSubmitting ? (
            <Spinner />
          ) : (
            <Button
              type="submit"
              message="Close your account"
              cssClasses={
                !values.disableAccount
                  ? ['dtRed', 'opacity7', 'biggerFontSize', 'opacityBorder']
                  : ['dtRed', 'biggerFontSize', 'transparentBorder']
              }
              disabled={!values.disableAccount}
              className={styles.closeYourAccountButton}
            />
          )}
        </div>
        <div className={styles.privacyPolicyTextWrapper}>
          <TextElement cssClasses={['fw400', 'white']}>
            For more information, review our
          </TextElement>{' '}
          <a
            href="https://support.7plus.com.au/hc/en-au/requests/new"
            target="_blank"
            rel="noreferrer"
          >
            <TextElement
              className={styles.privacyPolicyText}
              cssClasses={['fw600', 'white']}
            >
              Privacy Policy
            </TextElement>
          </a>
        </div>
      </form>
    </div>
  );
};
