import { logError } from '##/utils/trackJs';

export interface IImageSrcSetSizes {
  height?: number;
  media: string;
  width?: number;
}

export interface IBreakpoint {
  mobile1: number;
  mobile2: number;
  mobile3: number;
  mobile4: number;
  tablet: number;
  desktop1: number;
  desktop2: number;
  desktop3: number;
}

const {
  services: {
    endpoints: { imageproxy: imageProxyURL },
  },
} = window.swm.config;

interface IgetImageProxyUrl {
  height?: number;
  quality?: number;
  url: string;
  width?: number;
}

/**
 * Returns formatted image proxy url
 *
 * height: The height that will be requested for image proxy.
 * quality: Quality param that will be added in output formatted image proxy url.
 * url: Image url that will be added in the output formatted image proxy url.
 * width: The width that will be requested for image proxy, when 'height' is not defined.
 */
export const getImageProxyUrl = ({
  height,
  quality,
  url,
  width,
}: IgetImageProxyUrl): string => {
  const imageQuality: number = quality || window.swm.config.images.quality;
  let sizeParam = '';

  if (height) {
    sizeParam = `&height=${height}`;
  } else if (width) {
    sizeParam = `&w=${width}`;
  } else {
    logError(
      `Requesting image without width and height! url: [${url}] quality: [${quality}]`,
    );
  }

  if (imageProxyURL != null) {
    return `${imageProxyURL}?u=${url}&q=${imageQuality}${sizeParam}`;
  }

  return url;
};

export const constructSrcSet = (
  src: string,
  sizes: IImageSrcSetSizes[],
  quality: number,
): string => {
  return sizes
    .map(
      ({ height, media, width }) =>
        `${getImageProxyUrl({
          height,
          quality,
          url: src,
          width,
        })} ${media}`,
    )
    .join(', ');
};

export const getMultiplierBasedOnDevicePixelRatio = (
  pixelRatio: number = window.devicePixelRatio,
): number => {
  if (pixelRatio > 1 && pixelRatio < 3) {
    return 2;
  }

  if (pixelRatio >= 3) {
    return 3;
  }

  return 1;

  // TODO: need to test if the mapping above (reduced pixel ratio) is acceptable, otherwise it'll
  //       just return 'pixelRatio'. It'd be still useful to keep this function in case we want to
  //       adjust the pixelRatio to something slightly different
  // return pixelRatio;
};

const getDimensionBasedOnDevicePixelRatio = (
  pixelRatio: number,
  dimension?: number,
) =>
  dimension
    ? Math.round(dimension * getMultiplierBasedOnDevicePixelRatio(pixelRatio))
    : undefined;

export const getSrcSetSizes = ({
  width,
  height,
}: {
  width?: number;
  height?: number;
}): IImageSrcSetSizes[] => [
  {
    height: getDimensionBasedOnDevicePixelRatio(1, height),
    media: '1x',
    width: getDimensionBasedOnDevicePixelRatio(1, width),
  },
  {
    height: getDimensionBasedOnDevicePixelRatio(2, height),
    media: '2x',
    width: getDimensionBasedOnDevicePixelRatio(2, width),
  },
  {
    height: getDimensionBasedOnDevicePixelRatio(3, height),
    media: '3x',
    width: getDimensionBasedOnDevicePixelRatio(3, width),
  },
];

/**
 * By defining a limited number of breakpoints (currently 8) will allow us to have a better cache
 * in image proxy instead of having hundreds of possible values of the image width. That has been
 * the case when we just requested the image width to be the 'document.body.clientWidth' when it
 * was smaller than the desktop breakpoint.
 */
const breakpoints: IBreakpoint = {
  mobile1: 320,
  mobile2: 360,
  mobile3: 375,
  mobile4: 414,
  tablet: 768,
  desktop1: 1024,
  desktop2: 1440,
  desktop3: 1920,
};

/**
 * Returns the image width/height according to the display size, the existing breakpoints
 * and imageDimensionPerBreakpoint mapping
 *
 * @param imageDimensionPerBreakpoint The image width/height mapped by existing
 * breakpoints
 * @param isHeight Indicates if it should compare with client height (when true) or client width
 */
export const getDimension = (
  imageDimensionPerBreakpoint: IBreakpoint,
  isHeight: boolean = false,
): number => {
  const clientDimension: number = isHeight
    ? document.body.clientHeight
    : document.body.clientWidth;
  if (clientDimension > breakpoints.desktop2) {
    return imageDimensionPerBreakpoint.desktop3;
  }

  if (clientDimension > breakpoints.desktop1) {
    return imageDimensionPerBreakpoint.desktop2;
  }

  if (clientDimension > breakpoints.tablet) {
    return imageDimensionPerBreakpoint.desktop1;
  }

  if (clientDimension > breakpoints.mobile4) {
    return imageDimensionPerBreakpoint.tablet;
  }

  if (clientDimension > breakpoints.mobile3) {
    return imageDimensionPerBreakpoint.mobile4;
  }

  if (clientDimension > breakpoints.mobile2) {
    return imageDimensionPerBreakpoint.mobile3;
  }

  if (clientDimension > breakpoints.mobile1) {
    return imageDimensionPerBreakpoint.mobile2;
  }
  return imageDimensionPerBreakpoint.mobile1;
};
