import { Backdrop, IBackdropProps } from '##/components/Backdrop';
import { Button, IButtonProps } from '##/components/Button';
import { lockScroll, unlockScroll } from '##/utils/scroll';
import clsx from 'clsx';
import React, { useEffect, useState, useRef, CSSProperties } from 'react';
import FocusTrap from 'focus-trap-react';
import styles from './Dialog.pcss';

import IconClose from '##/assets/img/icon-close.svg';
import RedCloseIcon from '##/utils/icons/svgs/RedCloseIcon';

export const SCROLL_LOCK_CLASS = 'scroll-lock';
export const DIALOG_CLOSE_TRIGGER_CLASS = 'close-dialog';
export const DIALOG_OPEN_EVENT_NAME = 'dialog-open';

export interface IDialogProps {
  /** props to pass to the Backdrop component. */
  backdropProps?: IBackdropProps;
  buttons?: IButtonProps[];
  /** When `false` only buttons or isOpen prop can close the dialog. */
  canClose?: boolean;
  /** Center align content text. */
  center?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  /** An additional CSS class. */
  className?: string;
  hasBackdrop?: boolean;
  /** Will open when set to `true`. */
  isOpen?: boolean;
  /** An element to add a scroll lock CSS class to. */
  lockEl?: Element;
  narrow?: boolean;
  /** Should the dialog be kept open even if another also opens on top? */
  persistent?: boolean;
  wide?: boolean;
  /** Function which mutates the `isOpen` prop to equal `false`. */
  onClose(): void;
  closeDialogCallback?(): void;
  /** Toggles the blur effect on content. */
  noFade?: boolean;
  isFullScreenOnMobile?: boolean;
  buttonsStacked?: boolean;
  blackTheme?: boolean;
  fixedPadding?: boolean;
  skipScrollLock?: boolean;
  contentStyle?: CSSProperties;
}

/**
 * Dialog React component.
 * @description Creates a modal dialog box with an optional backdrop. Opening and
 * closing is controlled via the "isOpen" + "onClose" props. Buttons are optional
 * and can close the dialog when they have "className: 'close-dialog'". To create
 * a button/s you should pass Button component props to the `buttons` prop. You
 * can see an example implementation in `Dialog.test.js`.
 */
export const Dialog: React.FunctionComponent<IDialogProps> = ({
  backdropProps = {
    zIndex: 'auto',
  },
  canClose = true,
  center = true,
  closeDialogCallback = () => {},
  hasBackdrop = true,
  lockEl = document.body,
  narrow = false,
  persistent = false,
  wide = false,
  noFade = false,
  isFullScreenOnMobile = true,
  buttonsStacked = false,
  fixedPadding = false,
  blackTheme = false,
  buttons,
  className,
  isOpen,
  onClose,
  children,
  skipScrollLock = false,
  contentStyle,
}) => {
  const dialogBox = useRef(null);
  const [scrollLocked, setScrollLocked] = useState(false);

  useEffect(() => {
    if (isOpen && dialogBox.current) {
      dialogBox.current.focus();
    }
    return () => {
      // let's be extra sure this listener is removed
      if (onClose != null) {
        window.removeEventListener(DIALOG_OPEN_EVENT_NAME, onClose);
      }
      // make sure we dont unlock twice the same element
      if (scrollLocked) {
        // make sure we unlock the scroll
        unlockScroll(lockEl);
      }
    };
  }, [isOpen, dialogBox]);

  const controlScrollLock = () => {
    if (isOpen) {
      // notify other dialogs that a new one has opened
      const dialogOpenEvent = new CustomEvent(DIALOG_OPEN_EVENT_NAME);
      window.dispatchEvent(dialogOpenEvent);

      // make sure we dont lock twice the same element
      if (!scrollLocked) {
        // prevent scrolling
        if (!skipScrollLock) {
          lockScroll(lockEl);
        } else {
          lockEl.classList.add(styles.lockWithoutScroll);
        }
        setScrollLocked(true);
      }

      if (!persistent) {
        // run at the end of the execution stack to avoid race condition where
        // the above dispatchEvent() triggers this dialog to close itself
        setTimeout(() => {
          // set up handler to close this dialog if another opens
          window.addEventListener(DIALOG_OPEN_EVENT_NAME, onClose);
        }, 0);
      }
    } else {
      // make sure we don't unlock twice the same element
      if (scrollLocked) {
        if (!skipScrollLock) {
          unlockScroll(lockEl);
        } else {
          lockEl.classList.remove(styles.lockWithoutScroll);
        }
        setScrollLocked(false);
      }

      // clean up event listener; avoid memory leaks yo!
      window.removeEventListener(DIALOG_OPEN_EVENT_NAME, onClose);
    }
  };

  controlScrollLock();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      (event.target as HTMLDivElement).classList.contains(
        DIALOG_CLOSE_TRIGGER_CLASS,
      )
    ) {
      closeDialogCallback();
    } else {
      onClose();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!canClose) return;

    const key = event.key || event.keyCode;

    if (key === 'Escape' || key === 27) {
      onClose();
    }
  };

  const dialogClass = clsx(
    styles.dialog,
    {
      [styles.center]: center,
      [styles.narrow]: narrow,
      [styles.open]: isOpen,
      [styles.wide]: wide,
      [styles.noFade]: noFade,
      [styles.fullScreen]: isFullScreenOnMobile,
      [styles.buttonsStacked]: buttonsStacked,
      [styles.black]: blackTheme,
    },
    className,
  );

  const backdropCss =
    hasBackdrop && backdropProps ? backdropProps : ({} as IBackdropProps);

  const backdropClass = clsx(styles.backdrop, {
    [`${backdropCss.className}`]: hasBackdrop && backdropProps,
  });

  // don't render anything to DOM if not open
  if (!isOpen) {
    return null;
  }

  return (
    // outer div is used to set the bounds of the backdrop
    // we need to allow clicking outside focus trap so we can be able to close ResetPassword Flash Alert
    <FocusTrap
      focusTrapOptions={{
        allowOutsideClick: true,
      }}
    >
      <div
        className={dialogClass}
        role="presentation"
        tabIndex={-1}
        onKeyDown={handleKeyDown}
      >
        {hasBackdrop && (
          <Backdrop
            fullscreen={backdropProps ? backdropProps.fullscreen : false} // false is the default value
            isOpen={backdropProps ? backdropProps.isOpen : true} // true is the default value
            className={backdropClass}
            zIndex={1}
          />
        )}

        <div
          ref={dialogBox}
          className={clsx(styles.box, {
            [styles.fixedPaddingBox]: fixedPadding,
          })}
          tabIndex={-1}
        >
          {canClose && (
            <button
              className={clsx({
                [styles.close]: !blackTheme && !skipScrollLock,
                [styles.blackThemeClose]: blackTheme || skipScrollLock,
              })}
              onClick={onClose}
              type="button"
            >
              {blackTheme || skipScrollLock ? (
                <RedCloseIcon fill="currentColor" />
              ) : (
                <img src={IconClose} alt="close dialog button" />
              )}
            </button>
          )}
          <div className={styles.content} style={contentStyle}>
            {children}
          </div>
          {buttons && (
            <div
              className={styles.buttons}
              role="presentation"
              onClick={handleClick}
            >
              {buttons &&
                buttons.map(({ className, message, onClick, cssClasses }) => (
                  <Button
                    className={className}
                    cssClasses={cssClasses}
                    key={message}
                    message={message}
                    onClick={onClick}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </FocusTrap>
  );
};
