import { useState, useEffect } from 'react';

export const useGeolocationPermission = () => {
  const [locationAllowed, setLocationAllowed] = useState(false);
  const [locationStatus, setLocationStatus] = useState(undefined);

  useEffect(() => {
    if (!navigator.permissions) {
      return;
    }

    let permissionStatus: PermissionStatus;

    const handlePermissionChange = () => {
      setLocationAllowed(permissionStatus?.state === 'granted');
      setLocationStatus(permissionStatus?.state);
    };

    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      permissionStatus = result;
      setLocationAllowed(result.state === 'granted');
      setLocationStatus(result.state);
      result.addEventListener('change', handlePermissionChange);
    });
    return () => {
      if (!permissionStatus) {
        return;
      }
      permissionStatus.removeEventListener('change', handlePermissionChange);
    };
  }, []);

  return {
    locationAllowed,
    locationStatus,
  };
};

export default useGeolocationPermission;
